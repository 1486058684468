export function initMap() {
    const mapEl = document.querySelector('[data-map-holder]') as HTMLElement
    if (!mapEl) return

    const officeAddress = { lat: 50.06522090634019, lng: 14.502063876148325 }

    const mapOffice = new google.maps.Map(mapEl, {
        center: officeAddress,
        zoom: 18, // Higher zoom level to trigger 3D buildings
        disableDefaultUI: true,
        styles: [
            {
                "featureType": "all",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "weight": "2.00"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#9c9c9c"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#E2E9E7"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#E2E9E7"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#F6F6F5"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made.building",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#EEEBE6"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#7b7b7b"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#46bcec"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#b5dae1"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#070707"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            }
        ]
    });

    const marker = new google.maps.Marker({
        position: officeAddress,
        icon: new URL('@images/map-icon.svg', import.meta.url).href,
        map: mapOffice
    });

    mapOffice.addListener("tilesloaded", () => mapEl.classList.remove('is-loading'))

    function addZoomControls(map: google.maps.Map) {
        const zoomInButton = document.createElement("button");
        const zoomOutButton = document.createElement("button");

        zoomInButton.textContent = "+";
        zoomOutButton.textContent = "-";

        zoomInButton.classList.add("zoom-control");
        zoomOutButton.classList.add("zoom-control");
        
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomOutButton);
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomInButton);

        zoomInButton.addEventListener("click", () => {
            map.setZoom(map.getZoom()! + 1);
        });

        zoomOutButton.addEventListener("click", () => {
            map.setZoom(map.getZoom()! - 1);
        });
    }

    addZoomControls(mapOffice);
}   